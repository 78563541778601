import React from "react";
import "./AboutUs.css";
import banner from "../assets/Banner.png";
const AboutUs = () => {
  return (
    <div>
      <div className="main">
        <div className="container">
          <div className="background-image">
            <img
              src="https://rocketcrackers.com/wp-content/uploads/2024/01/About-2.png"
              alt=""
            />
            <h2 className="about-us">About Us</h2>
          </div>

          <div className="box-contents">
            <div className="content-1">
              <h2 className="content-1-title">
                <span className="title-1">
                  Premier Wholesale Dealer Offering a Wide Range of Fancy
                  Crackers, including Sivakasi Crackers and Diwali Crackers
                </span>
              </h2>
            </div>
            <div className="content-2">
              <h2 className="content-2-title">
                <span className="title-2">Rocket Crackers</span>
              </h2>
            </div>
            <div className="content-3">
              <p className="content-3-title">
                <span
                  className="title-3"
                  style={{ textDecoration: "underline" }}
                >
                  {" "}
                  <strong>
                    {" "}
                    Welcome to Rocket Crackers – Your Premier Destination for
                    Diwali Sivakasi Crackers Online!
                  </strong>{" "}
                </span>{" "}
                <br />
                <span className="title-3">
                  <strong>
                    {" "}
                    Sivakasi crackers online | Buy crackers online India |
                    Diwali crackers online shopping | Fireworks online India|
                    Sivakasi Pattasu Kadai|Crackers|Fireworks|{" "}
                  </strong>
                </span>
              </p>
            </div>
            <div className="content-4">
              <h2 className="headline">
                <strong>Company’s Journey</strong>
              </h2>
              <p className="summary">
                Rocket Crackers is a leading online retailer of crackers and
                fireworks, based in Sivakasi Pattasu Kadai with over 10 years of
                experience in the industry. We have been dedicated to providing
                our customers with top-quality products that add sparkle to
                their celebrations.
              </p>
              <h2 className="headline">
                <strong>Purpose and Goals</strong>
              </h2>
              <p className="summary">
                Our mission at Rocket Crackers is to offer a wide variety of
                crackers and fireworks to meet all your festive needs. We aim to
                bring joy and excitement to every celebration with our
                high-quality products that are perfect for Diwali, weddings,
                birthdays, and other special occasions.
              </p>
              <h2 className="headline">
                <strong>Introduction to the Team</strong>
              </h2>
              <p className="summary">
                At Rocket Crackers, we have a team of dedicated professionals
                who are passionate about providing excellent customer service
                and ensuring that you have the best shopping experience with us.
                Our team is committed to helping you find the perfect crackers
                and fireworks for your event.
              </p>
              <h2 className="headline">
                <strong>Offerings</strong>
              </h2>
              <p className="summary">
                Discover an extensive range of crackers and fireworks at Rocket
                Crackers. Our online store offers a wide variety of products,
                including sparklers, ground chakkars, flower pots, twinkling
                stars, pencils, atom bombs, rockets, bijili crackers, bird
                crackers, laxmi crackers, aerial shots, fancy fireworks, color
                matches, Diwali gift boxes. We have everything you need to make
                your celebrations truly dazzling.
              </p>
              <h2 className="headline">
                <strong>Customer Opinions</strong>
              </h2>
              <p className="summary">
                “Our family has been purchasing crackers from Rocket Crackers
                for years, and we have never been disappointed. The quality of
                their products is unparalleled, and their customer service is
                exceptional. We highly recommend Rocket Crackers to anyone
                looking for high-quality fireworks.” – Satisfied Customer
              </p>
              <h2 className="headline">
                <strong>Achievements</strong>
              </h2>
              <p className="summary">
                Over the past decade, Rocket Crackers has established itself as
                a trusted name in the industry, known for delivering top-quality
                crackers and fireworks to customers across the country. We take
                pride in our achievements and look forward to continuing to
                serve our loyal customers. <br />
                Ready to make your celebrations truly memorable? Visit Rocket
                Crackers today and explore our wide range of crackers and
                fireworks. Place your order now and experience the excitement of
                our top-quality products. Celebrate with Rocket Crackers!
              </p>
            </div>
          </div>
          <div className="over-view">
            <div className="years">
              <div className="years-container">
                <span>1</span>
                <span>0</span>
                <span>&nbsp;</span>
                <span>+</span>
              </div>
            </div>
            <div className="years">
              <div className="years-container">
                <span>2</span>
                <span>0</span>
                <span>0</span>
                <span>&nbsp;</span>
                <span>+</span>
              </div>
            </div>
            <div className="years">
              <div className="years-container">
                <span>8</span>
                <span>0</span>
                <span>0</span>
                <span>&nbsp;</span>
                <span>+</span>
              </div>
            </div>
            <div className="des">
              <p>Years of Experience</p>
            </div>
            <div className="des">
              <p>Products</p>
            </div>
            <div className="des">
              <p>Satisfied Customer</p>
            </div>
          </div>

          <div className="container">
            <div className="banner">
              <img src={banner} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
