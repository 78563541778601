import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './cartItems.css';

const CartItems = () => {
  const [cart, setCart] = useState({});
  const [products, setProducts] = useState([]);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [whatsapp, setWhatsapp] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const navigate = useNavigate(); // Hook to programmatically navigate

  useEffect(() => {
    // Load cart and products from localStorage
    const storedCart = JSON.parse(localStorage.getItem('cart')) || {};
    const storedProducts = JSON.parse(localStorage.getItem('products')) || [];
    console.log('Stored Cart:', storedCart); // Debugging log
    console.log('Stored Products:', storedProducts); // Debugging log
    setCart(storedCart);
    setProducts(storedProducts);
  }, []);

  // Map cart items with their details
  const cartItems = Object.keys(cart).map(id => {
    const product = products.find(p => p.id === parseInt(id, 10));
    return product ? { ...product, quantity: cart[id] } : null;
  }).filter(item => item !== null);

  const calculateTotal = () => {
    return cartItems.reduce((total, item) => total + (item.quantity * item.sell_price), 0);
  };

  const incrementQuantity = (id) => {
    setCart((prevCart) => ({
      ...prevCart,
      [id]: (prevCart[id] || 0) + 1,
    }));
  };

  const decrementQuantity = (id) => {
    setCart((prevCart) => ({
      ...prevCart,
      [id]: Math.max((prevCart[id] || 0) - 1, 0),
    }));
  };

  const handlePlaceOrder = () => {
    const total = calculateTotal();
    
    if (total < 3000) {
      setError('Minimum order value is ₹3000. Click here to add more items.');
      setMessage('');
      return;
    }

    const orderDetails = cartItems.map(item => 
      `${item.name}: ${item.quantity} x ₹${item.sell_price} = ₹${item.quantity * item.sell_price}`
    ).join('\n');

    const orderMessage = `
*** New Order From Rocket Crackers ***
      Name: ${name}
      Phone: ${phone}
      Address: ${address}
      WhatsApp: ${whatsapp}
      
      Cart Items:
      ${orderDetails}
      
      Total: ₹${total}
    `.trim();

    const whatsappUrl = `https://wa.me/919944712211?text=${encodeURIComponent(orderMessage)}`;
    
    window.open(whatsappUrl, '_blank');
    setMessage('Thank you for shopping!');
    setError('');
  };

  const handleErrorClick = () => {
    navigate('/'); // Redirect to home page
  };

  return (
    <div className="cart-items-section">
      <h2 className="section-title">Cart Items</h2>
      {cartItems.length === 0 ? (
        <p>Your cart is empty.</p>
      ) : (
        <table className="cart-items-table">
          <thead>
            <tr>
              <th>Image</th>
              <th>Name</th>
              <th>Quantity</th>
              <th>Price</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {cartItems.map(item => (
              <tr key={item.id}>
                <td><img src={item.img} alt={item.name} className="cart-item-image" /></td>
                <td>{item.name}</td>
                <td>
                  <div className="product-quantity">
                    <button className="quantity-btn" onClick={() => decrementQuantity(item.id)}>
                      -
                    </button>
                    <input
                      type="text"
                      className="quantity-input"
                      value={cart[item.id] || 0}
                      readOnly
                    />
                    <button className="quantity-btn" onClick={() => incrementQuantity(item.id)}>
                      +
                    </button>
                  </div>
                </td>
                <td>₹{item.sell_price}</td>
                <td>₹{item.quantity * item.sell_price}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <div className="cart-total">
        <p>Total: ₹{calculateTotal()}</p>
      </div>
      <div className="checkout-form">
        <h3>Checkout</h3>
        <form onSubmit={(e) => { e.preventDefault(); handlePlaceOrder(); }}>
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Phone:</label>
            <input
              type="text"
              id="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="address">Address:</label>
            <input
              type="text"
              id="address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="whatsapp">WhatsApp Number:</label>
            <input
              type="text"
              id="whatsapp"
              value={whatsapp}
              onChange={(e) => setWhatsapp(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="place-order-btn">Place Order</button>
        </form>
      </div>
      {error && (
        <div className="error-message">
          {error} <button onClick={handleErrorClick}>Click here</button>
        </div>
      )}
      {message && <div className="thank-you-message">{message}</div>}
    </div>
  );
};

export default CartItems;
