// src/pages/HomePage.js
import React, { lazy, Suspense } from 'react';
import LazyLoad from 'react-lazyload';

const HeroSection = lazy(() => import('../components/HeroSection'));
const ProductList = lazy(() => import('../components/ProductList'));

const HomePage = ({ cart, setCart }) => {
  return (
    <div>
      <LazyLoad height={200} offset={100} once>
        <Suspense fallback={<div>Loading...</div>}>
          <HeroSection />
        </Suspense>
      </LazyLoad>
      <LazyLoad height={1000} offset={100} once>
        <Suspense fallback={<div>Loading...</div>}>
          <ProductList cart={cart} setCart={setCart} />
        </Suspense>
      </LazyLoad>
     
      {/* Other components or content for your HomePage */}
    </div>
  );
};

export default HomePage;
