import React from "react";
import "./Contact.css";
import { Link } from "react-router-dom";
import { FaLocationDot, FaPhone } from "react-icons/fa6";
import { IoMdMailOpen } from "react-icons/io";

const Contact = () => {
  return (
    <div>
      <main id="jupiterx-main" className="jupiterx-main">
        <div className="jupiter-child">
          <div
            className="google_maps"
            data-id="ce0eeb5"
            data-element_type="widget"
            data-widget_type="google_maps.default"
          >
            <div className="custom-embed">
              <iframe
                loading="lazy"
                src="https://maps.google.com/maps?q=9%2F290%2F1%2C%20Anuppankulam%2CSivakasi%2CTamilnadu%20-%20626%20123.&#038;t=m&#038;z=16&#038;output=embed&#038;iwloc=near"
                title="9/290/1, Anuppankulam,Sivakasi,Tamilnadu - 626 123."
                aria-label="9/290/1, Anuppankulam,Sivakasi,Tamilnadu - 626 123."
              ></iframe>
            </div>
          </div>

          <section className="contact">
            <div className="box-1">
              <div className="get">
                <span className="raven-heading-title ">Get in Touch</span>
              </div>
              <div className="best">
                <p>Best wholesale Dealer for all kind of fancy crackers.</p>
              </div>
              <div className="form-container">
                <form className="-form" method="post" name="New form">
                  <input type="hidden" name="post_id" value="50" />
                  <input type="hidden" name="form_id" value="586c8ec" />
                  <input
                    type="text"
                    name="fields[60c20e1]"
                    id="form-field-60c20e1"
                    className="raven-field"
                    placeholder="Full Name"
                    data-type="text"
                  />

                  <input
                    type="tel"
                    name="fields[9fb89fc]"
                    id="form-field-9fb89fc"
                    className="raven-field"
                    placeholder="Phone"
                    data-type="tel"
                    pattern="^[0-9\-\+\s\(\)]*$"
                    title="The value should only consist numbers and phone characters (-, +, (), etc)"
                  />

                  <input
                    type="email"
                    name="fields[0b7c3a6]"
                    id="form-field-0b7c3a6"
                    className="raven-field"
                    placeholder="Email Address"
                    data-type="email"
                    required="required"
                  />
                  <textarea
                    type="textarea"
                    name="fields[765c403]"
                    id="form-field-765c403"
                    className="raven-field"
                    placeholder="Message"
                    data-type="textarea"
                    rows="5"
                  ></textarea>
                  <button className="raven-submit-button" type="submit">
                    <span>Submit</span>
                  </button>
                </form>
              </div>
            </div>

            <section className="contact-2">
              <div className="contact-box-2">
                <h3 className="detail">
                  <span className="raven-heading-title ">Contact Detail</span>
                </h3>
                <div className="detail-container">
                  <Link
                    rel="stylesheet"
                    href="https://rocketcrackers.com/wp-content/plugins/elementor/assets/css/widget-icon-list.min.css"
                  ></Link>
                  <ul className="list-items">
                    <li className="list-item">
                      <span className="list-icon">
                        <FaLocationDot />
                      </span>
                      <span className="list-text">
                        9/290/1, Anuppankulam, Sivakasi, Tamilnadu - 626 123.
                      </span>
                    </li>
                    <li className="list-item">
                      <a href="mailto:maheshpapuly@gmail.com">
                        <span className="list-icon">
                          <IoMdMailOpen />
                        </span>
                        <span className="list-text">
                          rocketcrackers73@gmail.com
                        </span>
                      </a>
                    </li>
                    <li className="list-item">
                      <a href="tel:(+91)%209043936473">
                        <span className="list-icon">
                          <FaPhone />
                        </span>
                        <span className="list-text">(+91) 9043936473</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </section>
          </section>
        </div>
      </main>
    </div>
  );
};

export default Contact;
