import React from "react";
import "./Footer.css";
import { FaLocationDot, FaPhone, FaCcMastercard } from "react-icons/fa6";
import { IoMdMailOpen, IoIosArrowForward } from "react-icons/io";
import { SiVisa } from "react-icons/si";
import { FaCcPaypal } from "react-icons/fa";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <div>
      <div className="footer">
        <div className="header-content">
          <Link to="/">
            <div className="badge">Genuine Price</div>
          </Link>
          <Link to="/cart">
            <div className="badge">Home Delivery</div>
          </Link>
          <h1>Customer Satisfaction</h1>
          <p>Our quality and timely delivery has attracted customers easily</p>
        </div>
      </div>

      <div className="content">
        <div className="contact-box">
          <h3 className="details">
            <span className="heading-titles ">Rocket Crackers</span>
          </h3>
          <div className="detail-cont">
            <Link
              rel="stylesheet"
              to="https://rocketcrackers.com/wp-content/plugins/elementor/assets/css/widget-icon-list.min.css"
            ></Link>
            <ul className="list-items">
              <li className="list-item">
                <span className="list-icon">
                  <FaLocationDot />
                </span>
                <span className="list-text">
                  9/290/1, Anuppankulam, Sivakasi, Tamilnadu - 626 123.
                </span>
              </li>
              <li className="list-item">
                <Link to="mailto:maheshpapuly@gmail.com">
                  <span className="list-icon">
                    <IoMdMailOpen />
                  </span>
                  <span className="list-text">rocketcrackers73@gmail.com</span>
                </Link>
              </li>
              <li className="list-item">
                <Link to="tel:(+91)%209043936473">
                  <span className="list-icon">
                    <FaPhone />
                  </span>
                  <span className="list-text">(+91) 9043936473</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="newsletter">
          <p className="d-o">Diwali Offer</p>
          <h1 className="sign-up">Sign up for Our Newsletter</h1>
          <form>
            <input type="email" placeholder="Email Address" required />
            <button type="submit">
              <IoIosArrowForward />
            </button>
          </form>
        </div>
      </div>

      <footer>
        <div className="footer-li">
          <div className="footer-section">
            <h4>Support</h4>
            <ul>
              <li>
                <Link to="/safety-tips">Safety Tips</Link>
              </li>
              <li>
                <Link to="/">Shipping and Returns</Link>
              </li>
              <li>
                <Link to="#">Customer Support</Link>
              </li>
              <li>
                <Link to="#">Bulk Order</Link>
              </li>
            </ul>
          </div>
          <div className="footer-section">
            <h4>Site Map</h4>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/">All Categories</Link>
              </li>
              <li>
                <Link to="/about-us">About</Link>
              </li>
              <li>
                <Link to="/contact-us">Contact</Link>
              </li>
            </ul>
          </div>
          <div className="footer-section">
            <h4>Best Sales</h4>
            <ul>
              <li>
                <Link to="#">Night Fancy Novelties</Link>
              </li>
              <li>
                <Link to="#">Sparklers</Link>
              </li>
              <li>
                <Link to="#">Multi Colour Shots</Link>
              </li>
              <li>
                <Link to="#">Twinkling</Link>
              </li>
            </ul>
          </div>
          <div className="footer-section">
            <h4>New Arrivals</h4>
            <ul>
              <li>
                <Link to="#">Stall Fills</Link>
              </li>
              <li>
                <Link to="#">Top Gun</Link>
              </li>
              <li>
                <Link to="#">Drone</Link>
              </li>
              <li>
                <Link to="#">Motto Wheel</Link>
              </li>
            </ul>
          </div>
        </div>
      </footer>
      <div className="footer-content">
        <p>
          As per 2018 supreme court order, online sale of firecrackers are not
          permitted! We value our customers and at the same time, respect
          jurisdiction. We request you to add your products to the cart and
          submit the required crackers through the enquiry button. We will
          contact you within 24 hrs and confirm the order through WhatsApp or
          phone call. Please add and submit your enquiries and enjoy your Diwali
          with Rocket Crackers. Our License No.—-. Rocket Crackers as a company
          following 100% legal & statutory compliances and all our shops,
          go-downs are maintained as per the explosive acts. We send the parcels
          through registered and legal transport service providers as like every
          other major companies in Sivakasi is doing so.
        </p>
      </div>
      <div className="last-footer">
        <div className="rights">
          © 2024 Website Created and Maintained by{" "}
          <Link to="https://cal.riobizsols.com/">RIOBIZSOLS PVT LTD.</Link>
          ALL RIGHTS RESERVED
        </div>
        <div className="payment-icons">
          {" "}
          <SiVisa /> <FaCcMastercard /> <FaCcPaypal />
        </div>
      </div>
    </div>
  );
};

export default Footer;
