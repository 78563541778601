// App.js
import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import CartItems from "./components/CartItems";
import AboutUs from "./components/AboutUs";
import Contact from "./components/Contact";
import SafetyTips from "./components/Saftytips";
import Header from "./components/Header";
import { getCategories, getProductsByCategory } from "./components/apiService";
import Footer from "./components/Footer";

function App() {
  const [cart, setCart] = useState(
    () => JSON.parse(localStorage.getItem("cart")) || {}
  );
  const [products, setProducts] = useState({});

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cart));
  }, [cart]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const categories = await getCategories();
        const category = categories[0]; // Fetch products for the first category as an example
        const productsData = await getProductsByCategory(category);
        setProducts(productsData);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, []);

  return (
    <main>
      <Header cart={cart} setCart={setCart} />
      <Routes>
        <Route path="/" element={<HomePage cart={cart} setCart={setCart} />} />
        <Route
          path="/cart"
          element={<CartItems cart={cart} products={products} />}
        />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/safety-tips" element={<SafetyTips />} />
      </Routes>
      <Footer />
    </main>
  );
}

export default App;
