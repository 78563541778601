import axios from 'axios';

const BASE_URL = 'https://api.allbills.in/rocket';

export const getCategories = async () => {
  const response = await axios.get(`${BASE_URL}/list-category`);
  console.log(response.data.categories)
  return response.data.categories;
};

export const getProductsByCategory = async (category) => {
console.log("cad",category)
  const response = await axios.get(`${BASE_URL}/view-by-category/${category.category}`);
  console.log("productresp",response)
  return response.data.products;
};
