import React from 'react';
import './SafetyTips.css';

const SafetyTips = () => {
  return (
    <div className="safety-tips-container">
      <div className="safety-tips-header">
        <h1>Rocket Cracker</h1>
        <p>There are certain Dos & Don'ts to follow while purchasing, bursting, and storing crackers. Thus, it is very important to follow the precautions while bursting crackers. A little negligence, ignorance, and carelessness can cause a fatal injury.</p>
      </div>
      <div className="safety-tips-content">
        <div className="safety-tips-do">
          <h2>Do's</h2>
          <ul className="safety-tips-ul">
            <li className="safety-tips-li"><span>Follow Instructions</span> — Display fireworks as per the instructions mentioned on the pack.</li>
            <li className="safety-tips-li"><span>Use Branded Fireworks</span></li>
            <li className="safety-tips-li"><span>Outdoor Use Only</span></li>
            <li className="safety-tips-li"><span>Keep Safe Distance</span></li>
            <li className="safety-tips-li"><span>Follow Safety Tips</span></li>
            <li className="safety-tips-li"><span>Use Agarbatti</span></li>
            <li className="safety-tips-li"><span>Safe Storage</span></li>
            <li className="safety-tips-li"><span>Needs Supervision</span></li>
            <li className="safety-tips-li"><span>Emergency Water</span></li>
          </ul>
        </div>
        <div className="safety-tips-dont">
          <h2>Don'ts</h2>
          <ul className="safety-tips-ul">
            <li className="safety-tips-li"><span>Don’t make tricks</span> — Never make your own fireworks.</li>
            <li className="safety-tips-li"><span>Don’t relight</span></li>
            <li className="safety-tips-li"><span>Don’t carry it</span></li>
            <li className="safety-tips-li"><span>Do not use Glass / Metal</span></li>
            <li className="safety-tips-li"><span>Do not Throw</span></li>
            <li className="safety-tips-li"><span>Don’t wear loose clothes</span></li>
            <li className="safety-tips-li"><span>Don’t Touch it</span></li>
            <li className="safety-tips-li"><span>Don’t place near candles</span></li>
            <li className="safety-tips-li"><span>Don’t Drink & Burst Crackers</span></li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SafetyTips;
