import React, { useState } from "react";
import "./Header.css";
import { FaSearch, FaShoppingCart, FaBars } from "react-icons/fa";
import { Link } from "react-router-dom";

const Header = ({ cart }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  // Calculate the total number of items in the cart
  const cartItemsCount = Object.values(cart).reduce(
    (total, quantity) => total + quantity,
    0
  );

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen); // Toggle the state to show/hide the menu
  };

  return (
    <header className="header">
      <div className="top-bar">
        <marquee className="offer-marquee">
          90 % Offer for Diwali Sale - Crackers Available 365 Days. Order now
          And Get Delivery immediately. Minimum Order 3000/-
        </marquee>
      </div>
      <div className="main-header">
        <img
          src="https://res.cloudinary.com/villagehosting-top-ssd-web-hosting-provider/image/upload/v1724604167/Rocket-Logo_ppzjc9.png"
          alt="Rocket Crackers Logo"
          className="logo"
        />
        <div className="search">
          <input type="text" placeholder="Search..." />
          <button>
            <FaSearch />
          </button>
        </div>
        <div className="cart">
          <Link to="/cart">
            <FaShoppingCart />
            <span>{cartItemsCount}</span>
          </Link>
        </div>
        <button className="mobile-menu-icon" onClick={toggleMobileMenu}>
          <FaBars />
        </button>
      </div>
      <nav className={`navigation ${isMobileMenuOpen ? "open" : ""}`}>
        <ul>
          <li>
            <a href="/">Home</a>
          </li>
          <li>
            <a href="/about-us">About</a>
          </li>
          <li>
            <a href="/">Price List</a>
          </li>
          <li>
            <a href="/safety-tips">Safety Tips</a>
          </li>
          <li>
            <a href="#/blog">Blog</a>
          </li>
          <li>
            <a href="/contact-us">Contact</a>
          </li>
          <li>
            <a href="#/gift-boxes">Gift Box Offers!</a>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
